import react from 'react';
import axios from 'axios';
import './menu.css';


class Menu extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            matches_subheading: false,
            lang: localStorage.getItem("civfr_lang")
        }
        this.display_sub_menu_matches = this.display_sub_menu_matches.bind(this);
        this.display_match_page_cpl = this.display_match_page_cpl.bind(this);
        this.display_match_page_cwc = this.display_match_page_cwc.bind(this);
        this.display_match_page_civfr = this.display_match_page_civfr.bind(this);
        this.display_match_page_others = this.display_match_page_others.bind(this);
        this.display_match_page_all = this.display_match_page_all.bind(this);
        this.display_user_bets = this.display_user_bets.bind(this);
        this.get_refill = this.get_refill.bind(this);
        this.reset_balance = this.reset_balance.bind(this);
        this.display_leaderboard = this.display_leaderboard.bind(this);
    };

    componentDidMount () {
        if (this.state.lang === 'FR') {
            document.getElementById("english-button-lang").style.color = 'gray';
            document.getElementById("uk-flag").style.filter = 'grayscale(1)';
        } else {
            document.getElementById("french-button-lang").style.color = 'gray';
            document.getElementById("french-flag").style.filter = 'grayscale(1)';
        }
    }

    display_sub_menu_matches () {
        switch (this.state.matches_subheading) {
            case false:
                this.setState({matches_subheading: true}, () => {
                    document.getElementById("matches-subheading").style.display = "initial";
                    document.getElementsByClassName("menu-actions")[0].style.position = 'relative';
                    document.getElementById("civ-bets-arrow").style.transform = "rotate(180deg)"
                });
                return;
            case true:
                this.setState({matches_subheading: false}, () => {
                    document.getElementById("matches-subheading").style.display = "none";
                    document.getElementsByClassName("menu-actions")[0].style.position = 'fixed';
                    document.getElementById("civ-bets-arrow").style.transform = "initial"
                });
                return;
            default:
                return;
        }
    }

    display_match_page_cwc () {
        this.props.display_match_page("CWC");
    }

    display_match_page_civfr () {
        this.props.display_match_page("Civ FR");
    }

    display_match_page_cpl () {
        this.props.display_match_page("CPL");
    }

    display_match_page_others () {
        this.props.display_match_page("Others");
    }

    display_match_page_all () {
        this.props.display_match_page("All");
    }

    display_user_bets () {
        this.props.display_user_bets();
    }

    display_leaderboard () {
        this.props.display_leaderboard();
    }

    get_refill () {
        axios.get('https://civfrcards.me/betting/daily_refill')
        .then((res) => { this.props.coins_refresh(); })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }

    reset_balance () {
        function verify () {
            return new Promise((resolve, reject) => {
                const MESSAGE = localStorage.getItem("civfr_lang") === "FR" ? "En réinitialisant votre solde à 10 000, tous les paris valides que vous avez seront réinitialisés. Es-tu sûr de vouloir faire ça?" : "By resetting your balance to 10000, any valid bets you have will be reset. Are you sure you want to start over?"
                // eslint-disable-next-line no-restricted-globals
                let confirmed = confirm((MESSAGE))
                if (confirmed) {
                    resolve(true);
                } else {
                    reject();
                }
            })
        }
        verify()
        .then(() => {
            axios.get('https://civfrcards.me/betting/reset_balance')
            .then((res) => { this.props.coins_refresh(); })
            .catch((err) => {
                if (err.response.status === 500) {
                    this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                } else {
                    window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
                }
            });
        })
        .catch((err) => {
            return;
        })
    }

    setEnglish () {
        localStorage.setItem("civfr_lang", "EN");
        document.getElementById("french-button-lang").style.color = 'gray';
        document.getElementById("french-flag").style.filter = 'grayscale(1)';
        document.getElementById("english-button-lang").style.color = 'white';
        document.getElementById("uk-flag").style.filter = 'none';
        window.location.href = "https://civfrcards.me/";
    }
    
    setFrench () {
        localStorage.setItem("civfr_lang", "FR");
        document.getElementById("english-button-lang").style.color = 'gray';
        document.getElementById("uk-flag").style.filter = 'grayscale(1)';
        document.getElementById("french-button-lang").style.color = 'white';
        document.getElementById("french-flag").style.filter = 'none';
        window.location.href = 'https://civfrcards.me/'
    }

    go_to_collections () {
        window.location.href = 'https://civfrcards.me/collections/'
    }

    render () {
        return (
            <div className="menu sidebar nav-menu" id="side-menu">
                <div className="menu-bets menu-option menu-dropdown" onClick={this.display_sub_menu_matches}>
                    <p className="menu-heading menu-dropdown">{this.state.lang === 'FR' ? "CIV PARIS" : "CIV BETS"}<img id="civ-bets-arrow" src="./down_arrow.png"></img></p>
                    <div className="menu-sub-headings" id="matches-subheading">
                        <div id="All" onClick={this.display_match_page_all}>
                            <img src="./bets_category_all.png" className="category-logos" onClick={this.display_match_page_all}></img>
                            <p onClick={this.display_match_page_all} id="all-category">ALL</p>
                        </div>
                        <div id="CWC" onClick={this.display_match_page_cwc}>
                            <img src="./bets_category_CWC.png" className="category-logos" onClick={this.display_match_page_cwc}/>
                            <p onClick={this.display_match_page_cwc} id="cwc-category">CWC</p>
                        </div>
                        <div id="Civ FR" onClick={this.display_match_page_civfr}>
                            <img src="./bets_category_CivFR.png" className="category-logos" onClick={this.display_match_page_civfr}/>
                            <p onClick={this.display_match_page_civfr} id="civ-category">Civ FR</p>
                        </div>
                        <div id="CPL" onClick={this.display_match_page_cpl}>
                            <img src="./bets_category_CPL.png" className="category-logos" onClick={this.display_match_page_cpl}/>
                            <p onClick={this.display_match_page_cpl} id="cpl-category">CPL</p>
                        </div>
                        <div id="Others" onClick={this.display_match_page_others}>
                            <img src="./bets_category_others.png" className="category-logos" onClick={this.display_match_page_others}/>
                            <p onClick={this.display_match_page_others} id="others-category">{localStorage.getItem("civfr_lang") === 'FR' ? "Autres" : "Others"}</p>
                        </div>
                    </div>
                </div>
                <div className="menu-leaderboard menu-option" onClick={this.display_leaderboard}>
                    <p className="menu-heading">{this.state.lang === 'FR' ? "CLASSEMENT" : "LEADERBOARD" }</p>
                </div>
                <div className="menu-my-bets menu-option" onClick={this.display_user_bets}>
                    <p className="menu-heading">{this.state.lang === 'FR' ? "MES PARIS" : "MY BETS" }</p>
                </div>
                <div className="menu-collection menu-option menu-dropdown" onClick={this.go_to_collections}>
                    <p className="menu-heading menu-dropdown  menu-dropdown">COLLECTIONS</p>
                </div>
                <div className="menu-actions">
                    <p id="menu-balance-refill" onClick={this.get_refill}><img className="menu-gold-coin" src="./coin.png" alt="gold coin"></img>{this.state.lang === 'FR' ? "RECHARGE" : "REFILL" }</p>
                    <p id="menu-balance-reset" onClick={this.reset_balance}><img className="menu-gold-coin" src="./coin.png" alt="gold coin"></img>{this.state.lang === 'FR' ? "RÉINITIALISER" : "RESET" }</p>
                    <div>
                        <span id="english-button-lang" onClick={this.setEnglish}><img id="uk-flag" className="flag" src="./Flag-uk.png" alt="united kingdom flag"></img> EN</span>
                        <span id="french-button-lang" onClick={this.setFrench}><img id="french-flag" className="flag" src="./Flag-France.png" alt="french flag"></img> FR</span>
                    </div>
                </div>
            </div>
        );
    };
};

export default Menu;