import react from 'react';
import './header.css';
import Login from './login';
import axios from 'axios';

class Header extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            sub_menu: false,
            balance: false,
            logged_out: true
        }
        this.pass_menu_display = this.pass_menu_display.bind(this);
        this.display_malm_page = this.display_malm_page.bind(this);
        this.reset_balance = this.reset_balance.bind(this);
        this.get_refill = this.get_refill.bind(this);
        this.display_match_page = this.display_match_page.bind(this);
        this.display_user_bets = this.display_user_bets.bind(this);
        this.display_leaderboard = this.display_leaderboard.bind(this);
        this.set_is_logged_out = this.set_is_logged_out.bind(this);
    };

    componentDidMount () {
        if (!this.state.logged_out) {
            axios.get('https://civfrcards.me/betting/user_balance')
            .then((res) => { this.setState({balance: res.data.balance}); })
            .catch((err) => { 
                if (err.response.status === 500) {
                    this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                } else {
                    window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
                }
            });
        }

        const LANG = localStorage.getItem("civfr_lang");
        if (LANG === 'FR') {
            document.getElementById("top-nav-uk-flag").style.color = 'gray';
            document.getElementById("uk-flag-desk").style.filter = 'grayscale(1)';
            if (parseInt(document.getElementById("top-nav-actions").style.width.slice(0,2)) > 24) {
                document.getElementById("top-nav-actions").style.width = '24%'
            }
        } else {
            document.getElementById("top-nav-french-flag").style.color = 'gray';
            document.getElementById("french-flag-desk").style.filter = 'grayscale(1)';
            if (parseInt(document.getElementById("top-nav-actions").style.width.slice(0,2)) > 21) {
                document.getElementById("top-nav-actions").style.width = '21%'
            }
        }
    }

    componentDidUpdate () {
        if (!this.state.logged_out) {
            axios.get('https://civfrcards.me/betting/user_balance')
            .then((res) => { 
                if (res.data.balance !== this.state.balance) {
                    this.setState({balance: res.data.balance});
                } 
            })
            .catch((err) => { 
                if (err.response.status === 500) {
                    this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                } else {
                    window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
                }
            });
        }
    }

    pass_menu_display () {
        switch (this.state.sub_menu) {
            case false:
                this.setState({sub_menu: true}, () => {
                    this.props.pass_side_menu();
                    document.getElementById("menu-icon").setAttribute("src", "./menu_refold.png");
                });
                break;
            case true:
                this.setState({sub_menu: false}, () => {
                    this.props.hide_side_menu();
                    document.getElementById("menu-icon").setAttribute("src", "./menu_unfold.png");
                });
                break;
            default:
                break;
        }
    }

    display_malm_page () {
        if (!this.state.logged_out) {
            axios.get('https://civfrcards.me/betting/validate_user')
            .then(() => { this.props.pass_admin(); })
            .catch((err) => { 
                if (err.response.status === 401) {
                    window.open("https://twitch.tv/malm", "_blank");
                } else if (err.response.status === 500) {
                    this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                }
            });
        }
    }

    setEnglish () {
        localStorage.setItem("civfr_lang", "EN");
        document.getElementById("top-nav-french-flag").style.color = 'gray';
        document.getElementById("french-flag-desk").style.filter = 'grayscale(1)';
        document.getElementById("top-nav-uk-flag").style.color = 'white';
        document.getElementById("uk-flag-desk").style.filter = 'none';
        if (parseInt(document.getElementById("top-nav-actions").style.width.slice(0,2)) > 21) {
            document.getElementById("top-nav-actions").style.width = '21%'
        }
        window.location.href = 'https://civfrcards.me/'
    }
    
    setFrench () {
        localStorage.setItem("civfr_lang", "FR");
        document.getElementById("top-nav-uk-flag").style.color = 'gray';
        document.getElementById("uk-flag-desk").style.filter = 'grayscale(1)';
        document.getElementById("top-nav-french-flag").style.color = 'white';
        document.getElementById("french-flag-desk").style.filter = 'none';
        if (parseInt(document.getElementById("top-nav-actions").style.width.slice(0,2)) > 24) {
            document.getElementById("top-nav-actions").style.width = '24%'
        }
        window.location.href = 'https://civfrcards.me/'
    }

    get_refill () {
        axios.get('https://civfrcards.me/betting/daily_refill')
        .then((res) => { this.props.coins_refresh(); })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }

    reset_balance () {
        function verify () {
            return new Promise((resolve, reject) => {
                const MESSAGE = localStorage.getItem("civfr_lang") === "FR" ? "En réinitialisant votre solde à 10 000, tous les paris valides que vous avez seront réinitialisés. Es-tu sûr de vouloir faire ça?" : "By resetting your balance to 10000, any valid bets you have will be reset. Are you sure you want to start over?"
                // eslint-disable-next-line no-restricted-globals
                let confirmed = confirm((MESSAGE))
                if (confirmed) {
                    resolve(true);
                } else {
                    reject();
                }
            })
        }
        verify()
        .then(() => {
            axios.get('https://civfrcards.me/betting/reset_balance')
            .then((res) => { this.props.coins_refresh(); })
            .catch((err) => {
                if (err.response.status === 500) {
                    this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                } else {
                    window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
                }
            });
        })
        .catch((err) => {
            return;
        })
    }
    
    display_match_page () {
        this.props.display_match_page('All')
    }

    display_user_bets () {
        this.props.display_user_bets();
    }

    display_leaderboard () {
        this.props.display_leaderboard();
    }

    go_to_collections () {
        window.location.href = 'https://civfrcards.me/collections/'
    }

    set_is_logged_out (logged_out) {
        this.setState({logged_out: logged_out}, () => {this.props.pass_logged_out(logged_out)})
    }

    render () {
        const BALANCE = this.state.logged_out 
        ? null 
        : this.state.balance === false 
        ? <p className="coin-balance"></p> 
        : <p className="coin-balance">{this.state.balance}<img src="./coin.png" alt="gold coin" className="header-coin-logo"></img></p>
        
        return (
            <div id="header">
                <div className="left heading-child">
                    <img src="./menu_unfold.png" id="menu-icon" onClick={this.pass_menu_display}></img>
                    <img className="malm-logo" src="./logo.png" alt="Malm Avatar" onClick={this.display_malm_page}></img>
                    <p onClick={this.display_malm_page} id="malm-title">MALM</p>
                </div>
                <div id="top-nav-items">
                    <span onClick={this.display_match_page}>{localStorage.getItem("civfr_lang") === 'FR' ? "CIV PARIS" : "CIV BETS"}</span>
                    <span onClick={this.display_leaderboard}>{localStorage.getItem("civfr_lang") === 'FR' ? "CLASSEMENT" : "LEADERBOARD"}</span>
                    <span onClick={this.display_user_bets}>{localStorage.getItem("civfr_lang") === 'FR' ? "MES PARIS" : "MY BETS" }</span>
                    <span onClick={this.go_to_collections}>COLLECTIONS</span>
                </div>
                <div id="top-nav-actions">
                    <span id="top-nav-uk-flag" onClick={this.setEnglish}><img className="uk-flag" id="uk-flag-desk" src="./Flag-uk.png"></img>EN</span>
                    <span id="top-nav-french-flag" onClick={this.setFrench}><img className="french-flag" id="french-flag-desk" src="./Flag-France.png"></img>FR</span>
                    <span className="top-nav-action" onClick={this.get_refill}>{localStorage.getItem("civfr_lang") === 'FR' ? "RECHARGE" : "REFILL" }</span>
                    <span className="top-nav-action" onClick={this.reset_balance}>{localStorage.getItem("civfr_lang") === 'FR' ? "RÉINITIALISER" : "RESET" }</span>
                </div>
                <div className="right heading-child">
                    {BALANCE}
                    <Login is_logged_out={this.set_is_logged_out} send_feedback={this.send_feedback_alert}/>
                </div>
            </div>
        );
    };
};

export default Header;