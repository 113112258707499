import react from 'react';
import './match-team-odds.css';
import axios from 'axios';

class MatchTeamOdds extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            match: {},
            chosen_team: null,
            active_bet: false
        }
        this.add_to_basket = this.add_to_basket.bind(this);
    };

    componentDidMount () {
        setInterval(() => {
            axios.get(`https://civfrcards.me/betting/update_odds?match_id=${this.state.match.match_id}`)
            .then((res) => {
                const MATCH = this.state.match;
                MATCH.team_one_odds = res.data.team_one_odds;
                MATCH.team_two_odds = res.data.team_two_odds;
                this.setState({match: MATCH})
            })
            .catch((err) => { return; });
        }, 30000);
    }

    static getDerivedStateFromProps(props, state) {
        const UPDATED_STATE = {};
        if (props.match !== state.match) { UPDATED_STATE.match = props.match; };
        if (props.active_bet.active) { 
            UPDATED_STATE.chosen_team = props.active_bet.chosen_team;
            UPDATED_STATE.active_bet = true;
        }
        return UPDATED_STATE;
    }

    add_to_basket (e) {
        if (this.state.active_bet) return;
        const CHOSEN_BET = e.currentTarget.id;
        const OPPONENT_ONE = e.currentTarget.title;
        axios.post('https://civfrcards.me/betting/add_to_basket', {
            match_id: parseInt(CHOSEN_BET), 
            chosen_team: OPPONENT_ONE
        })
        .then(() => { this.props.go_to_basket() })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }

    get_color (name) {
        if ((name === this.state.chosen_team) && this.state.active_bet) { 
            let teamName = "team" + name.split(" ").join("").replace(/[^a-zA-Z0-9À-ÖØ-öø-ÿ]+/g, "");
            if (this.state.chosen_team === this.state.match.team_one_name) {
                document.styleSheets[0].insertRule('div.' + teamName + this.state.match.match_id + '-odds-container::after { border-left: 15px solid orange !important; } ')
            } else {
                document.styleSheets[0].insertRule('div.' +  teamName + this.state.match.match_id + '-odds-container::before { border-right: 15px solid orange !important; } ')
            }
            return 'orange'; 
        }
        else { return };
    }
    
    render () {
        if (!this.state.match) return <div className="live-match-odds-container"></div>
        let teamOneName = "team" + this.state.match.team_one_name.split(" ").join("").replace(/[^a-zA-Z0-9À-ÖØ-öø-ÿ]+/g, "");
        let teamTwoName = "team" + this.state.match.team_two_name.split(" ").join("").replace(/[^a-zA-Z0-9À-ÖØ-öø-ÿ]+/g, "");
        return (
            <div className="live-match-odds-container">
                <div className={`match-left-team ${teamOneName + this.state.match.match_id}-odds-container`} style={{backgroundColor:this.get_color(this.state.match.team_one_name)}} id={this.state.match.match_id} title={this.state.match.team_one_name} onClick={this.add_to_basket}>
                    <span className="left-team-match-name">{this.state.match.team_one_name}</span>
                    <span className="left-team-match-odds">{this.state.match.team_one_odds.toFixed(2)}</span>
                </div>
                <div className="versus-text">
                    <span>VS</span>
                </div>
                <div className={`match-right-team ${teamTwoName + this.state.match.match_id}-odds-container`} style={{backgroundColor:this.get_color(this.state.match.team_two_name)}} id={this.state.match.match_id} title={this.state.match.team_two_name} onClick={this.add_to_basket}>
                    <span className="right-team-match-name">{this.state.match.team_two_name}</span>
                    <span className="right-team-match-odds">{this.state.match.team_two_odds.toFixed(2)}</span>
                </div>
            </div>
        );
    };
};

export default MatchTeamOdds;