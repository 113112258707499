import react from 'react';
import axios from 'axios';
import Keyboard from './keyboard';
import BasketReview from './basket-review';
import BasketItems from './basket-items';
import BasketBanner from './basket-banner';

class Basket extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            basket_item_id: null,
            basket_item_value: 100,
            basket_item: {},
            basket_count: 0,
            basket_total_bet: 0,
            basket_total_gains: 0,
            reset_basket: false,
            add_bets: false
        }
        this.set_basket_item_id = this.set_basket_item_id.bind(this);
        this.update_bet_amount = this.update_bet_amount.bind(this);
        this.reset_basket_item = this.reset_basket_item.bind(this);
        this.update_basket_details = this.update_basket_details.bind(this);
        this.clear_basket = this.clear_basket.bind(this);
        this.add_bets = this.add_bets.bind(this);
        this.reload_balance = this.reload_balance.bind(this);
        this.send_feedback_alert = this.send_feedback_alert.bind(this);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.basket_item !== state.basket_item) {
            return {
              basket_item: props.basket_item
            };
        } else return null;
    }

    set_basket_item_id (id, value) {
        this.setState({basket_item_id: id, basket_item_value: value})
    }

    update_bet_amount (value) {
        this.setState({basket_item_value: value});
        if (value.length === 0) { return; };
        axios.post('https://civfrcards.me/betting/update_bet_amount', {
            match_id: this.state.basket_item_id,
            amount: parseFloat(value).toFixed(2)
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }

    update_basket_details (basket) {
        let totalBets = 0;
        let totalReturns = 0;
        let totalItems = Object.keys(basket).length;
        if (totalItems === 0) { this.setState({basket_count: totalItems, basket_total_bet: totalBets, basket_total_gains: totalReturns})}
        else {
            Object.keys(basket).map((x, index) => {
                let oddsVar = basket[x].team_one_name === basket[x].chosen_team ? basket[x].team_one_odds : basket[x].team_two_odds;
                totalBets += parseFloat(basket[x].amount);
                totalReturns += (basket[x].amount * oddsVar);
                if (index === Object.keys(basket).length-1) this.setState({basket_count: totalItems, basket_total_bet: totalBets, basket_total_gains: totalReturns});
            })
        }
    }

    clear_basket () {
        this.setState({reset_basket: true}, () => this.setState({reset_basket: false}));
    }

    reset_basket_item () {
        this.props.reset_basket_item();
    }

    add_bets () {
        this.setState({add_bets: true}, () => this.setState({add_bets: false}));
    }

    reload_balance () {
        this.props.reload_balance();
    }

    send_feedback_alert (feedback) {
        this.props.send_feedback(feedback);
    }
    
    render () {
        return (
            <div id="basket">
                <BasketBanner send_feedback={this.send_feedback_alert} empty_basket={this.clear_basket} basketCount={this.state.basket_count}/>
                <BasketItems send_feedback={this.send_feedback_alert} reload_balance={this.reload_balance} add_basket_to_bets={this.submit_basket} add_bets={this.state.add_bets} reset_basket={this.state.reset_basket} pass_basket_items={this.update_basket_details} pass_basket_item_id={this.set_basket_item_id} reset_basket_item={this.reset_basket_item} new_basket_amount={this.state.basket_item_value} current_item_id={this.state.basket_item_id}/>
                <BasketReview send_feedback={this.send_feedback_alert} add_bets={this.add_bets} basketCount={this.state.basket_count} basketTotalBet={this.state.basket_total_bet} basketTotalGains={this.state.basket_total_gains}/>
                <Keyboard send_feedback={this.send_feedback_alert} basket_item_amount={this.state.basket_item_value} pass_back_bet_amount={this.update_bet_amount}/>
            </div>
        );
    };
};

export default Basket;