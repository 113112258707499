import react from 'react';
import axios from 'axios';
import './leaderboard.css';

class Leaderboard extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            leaderboard: [],
            user: [{}],
            lang: localStorage.getItem("civfr_lang")
        }
        this.load_leaderboard = this.load_leaderboard.bind(this);
    };

    componentDidMount () {
        this.load_leaderboard();
    }

    load_leaderboard () {
        axios.get('https://civfrcards.me/betting/leaderboard')
        .then((res) => {
            this.setState({leaderboard: res.data.leaderboard, user: res.data.user}); 
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email";
            }
        });
    }
    
    render () {
        return (
            <div id="leaderboard">
                <div id="leaderboard_banner">
                    <img src="./podium.png" alt="podium" id="leaderboard_podium"/>
                    <p id="leaderboard_banner_heading">{this.state.lang === 'FR' ? "CLASSEMENT" : "LEADERBOARD"}</p>
                </div>
                <div id="leaderboard-section-container">
                    <div id="leaderboard-container">
                        <div id="leaderboard_headings">
                            <span id="position_number_heading">#</span>
                            <span id="user_name_heading">{this.state.lang === 'FR' ? "NOM" : "NAME" }</span>
                            <span id="win_rate_heading">{this.state.lang === 'FR' ? "GAGNÉ" : "WON" }</span>
                            <span id="total_points_heading">POINTS</span>            
                        </div>
                        <div id="user_leaderboard_field">
                            {this.state.leaderboard.length ? (
                                <div className="user_leaderboard_data">
                                    <span className="leaderboard_position">{this.state.user[0].position}</span>
                                    <span className="leaderboard_username">{this.state.user[0].username}</span>
                                    <span className="leaderboard_percentage">{(this.state.user[0].wins !== 0) ? ((parseInt(this.state.user[0].wins) / (parseInt(this.state.user[0].wins) + parseInt(this.state.user[0].losses))) * 100).toFixed(2) : 0}%</span>
                                    <span className="leaderboard_balance">{this.state.user[0].balance}<img src="coin.png" className="points_gold_coin"/></span>
                                </div> 
                            ) : <p style={{color: 'white',textAlign: 'center', width: '100%'}}>{this.state.lang === 'FR' ? "Chargement" : "Loading" }</p>}
                        </div>
                        <div id="leaderboard_table">
                            {this.state.leaderboard.map((x, index) => {
                                const USER_WIN_PERCENTAGE = (x.wins !== 0) ? (parseInt(x.wins) / (parseInt(x.wins) + parseInt(x.losses))) * 100 : 0;
                                return (
                                    <div key={index} className="users_leaderboard_data">
                                        <span className="leaderboard_position">{index+1 === 1 ? <img src="./podium_1.png" id="first_place"></img> : index+1 === 2 ? <img src="./podium_2.png" id="second_place"></img> : index+1 === 3 ? <img src="./podium_3.png" id="third_place"></img> :index+1}</span>
                                        <span className="leaderboard_username">{x.username}</span>
                                        <span className="leaderboard_percentage">{USER_WIN_PERCENTAGE.toFixed(2)}%</span>
                                        <span className="leaderboard_balance">{x.balance}<img src="coin.png" className="points_gold_coin"/></span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div id="leaderboard_prizes_container">
                        <img src="./leaderboard_prizes.png" alt="Prizes available for podium leaderboard holders"></img>
                    </div>
                </div>
            </div>
        );
    };
};

export default Leaderboard;