import react from 'react';
import axios from 'axios';
import './betting-history.css';

class BetHistory extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            betting_history: []
        }
    };

    componentDidMount () {
        axios.get('https://civfrcards.me/betting/betting_history')
        .then((res) => { this.setState({betting_history: res.data}); })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }
    
    render () {
        return (
            <div id="betting_history">
                <div>
                    <h2>{localStorage.getItem("civfr_lang") === 'FR' ? 'Historique des paris' : 'Bet history'}</h2>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Match</th>
                            <th>Winner</th>
                            <th>{localStorage.getItem("civfr_lang") === 'FR' ? 'Montant' : 'Amount'}</th>
                            <th>+ -</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.betting_history.map((bet_item) => {
                            const COLOR = (bet_item.won === 1) ? 'green' : 'red';
                            return (
                                <tr>
                                    <td>{bet_item.match_teams}</td>
                                    <td>{bet_item.winning_team}</td>
                                    <td>{bet_item.bet_amount}</td>
                                    <td style={{color: COLOR}} className="bet-returns">{bet_item.returned}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        );
    };
};

export default BetHistory;