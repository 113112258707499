import react from 'react';
import './alert.css';

class AlertBox extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            alert: false,
            feedback: {type: 'error'}
        }
    };

    static getDerivedStateFromProps (props, state) {
        if (props.alert && (props.alert !== state.alert) && props.feedback !== state.feedback) {
            return {
                alert: true,
                feedback: props.feedback
            };
        } else return null;
    };

    render () {
        const FEEDBACK_ELEMENT = this.state.alert ? 
        <div id="feedback_alert_box" style={{backgroundColor: this.state.feedback.type === 'error' ? "red" : "green"}}>
            <p id="feedback_message" style={{color: 'white', fontSize: '12px'}}>{this.state.feedback.message}</p>
        </div>
        : null;

        return (
           FEEDBACK_ELEMENT
        );
    };
};

export default AlertBox