import react from 'react';
import MatchTeamOdds  from './match-team-odds';
import './upcoming-match.css';
import axios from 'axios';
import StreamEmbeds from '../live-streams/embeds';
import Settings from '../settings/settings';
import MatchBanner from './match-banner';

class UpcomingMatches extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            match_category: '',
            upcoming_matches: {},
            bets: [],
            authorized: false,
            lang: localStorage.getItem("civfr_lang")
        }
        this.go_to_basket = this.go_to_basket.bind(this);
        this.show_settings = this.show_settings.bind(this);
        this.load_data = this.load_data.bind(this);
        this.send_feedback_alert = this.send_feedback_alert.bind(this);
        this.set_category = this.set_category.bind(this);
        this.display_events = this.display_events.bind(this);
        this.alter_match_embed = this.alter_match_embed.bind(this);
    };

    componentDidMount () {
        this.load_data();
    }

    load_data () {
        axios.get(`https://civfrcards.me/betting/match_category?category=${this.state.match_category}`)
        .then((res) => {
            const MATCHES = res.data;
            if (MATCHES.length === 0) this.setState({upcoming_matches: {}})
            else {
                const UPCOMING_MATCHES = {};
                let matchIds = [];
                MATCHES.map((x) => { UPCOMING_MATCHES[x.match_id] = x; matchIds.push(x.match_id)});
                this.setState({upcoming_matches: UPCOMING_MATCHES, matchIds: matchIds});
            };
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.send_feedback_alert({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            }
        });
        
        axios.get('https://civfrcards.me/betting/my_bets')
        .then((res) => { this.setState({bets: res.data}); })
        .catch((err) => {
            if (err.response.status === 500) {
                this.send_feedback_alert({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });

        axios.get('https://civfrcards.me/betting/validate_user')
        .then(() => { 
            this.setState({authorized: true})
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.send_feedback_alert({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            }
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match_category !== state.match_category) {
            return {
              match_category: props.match_category
            };
        } else return null;
    }

    go_to_basket () {
        this.props.go_to_basket();
    }

    send_feedback_alert (feedback) {
        this.props.send_feedback(feedback)
    }

    show_settings (event) {
        if (this.state.authorized) {
            const MATCH_ID = event.target.name.split("_")[1];
            document.getElementById(event.target.name).style.display = 'none';
            document.getElementById(`settings_${MATCH_ID}`).style.display = 'initial';
        } 
    }

    alter_match_embed (event) {
        if (document.getElementById(event.target.id).src.split("/")[5] === 'down_arrow.png') {
            const MATCH_ID = event.target.id.split("-")[3];
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("live-stream-container")[0].style.display = 'initial';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("live-stream-container")[0].style.gridRow = '1/2';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("live-stream-container")[0].style.gridColumn = '1/16';
            document.getElementById(`match_${MATCH_ID}`).style.gridTemplateRows = '2fr 32.5px';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("upcoming-match-division")[0].style.gridRow = '2/3';
            if (this.state.authorized) {
                document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("cog-image")[0].style.gridRow = '2/3';
            }
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("upcoming-match-winner-span")[0].style.gridRow = '2/3';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("upcoming-match-date-span")[0].style.gridRow = '2/3';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("match_stream_arrow")[0].style.gridRow = '1/3';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("live-match-odds-container")[0].style.gridRow = '2/3';
            document.getElementById(event.target.id).setAttribute("src", './close_cross.png');
        } else if (document.getElementById(event.target.id).src.split("/")[5] === 'close_cross.png') {
            const MATCH_ID = event.target.id.split("-")[3];
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("live-stream-container")[0].style.display = 'none';
            document.getElementById(`match_${MATCH_ID}`).style.gridTemplateRows = '32.5px';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("upcoming-match-division")[0].style.gridRow = '1/2';
            if (this.state.authorized) {
                document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("cog-image")[0].style.gridRow = '1/2';
            }
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("upcoming-match-winner-span")[0].style.gridRow = '1/2';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("upcoming-match-date-span")[0].style.gridRow = '1/2';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("match_stream_arrow")[0].style.gridRow = '1/2';
            document.getElementById(`match_${MATCH_ID}`).getElementsByClassName("live-match-odds-container")[0].style.gridRow = '1/2';
            document.getElementById(event.target.id).setAttribute("src", './down_arrow.png');
        }
    }


    prepare_items () {
        if (Object.keys(this.state.upcoming_matches).length === 0) { return <p>{this.state.lang === 'FR' ? "Aucune correspondance à afficher" : "No matches to display" }</p> }
        else {
            return this.state.matchIds.map((match, index) => {
                let date = this.state.upcoming_matches[match].match_date
                let categoryDate = '';
                let dateElement = null;
                const is_today = new Date(date).toLocaleDateString().split(',')[0] === new Date().toLocaleDateString().split(',')[0];
                const HOURS = new Date().getHours();
                const MINS = new Date().getMinutes();
                const TIME = HOURS.toString() + MINS.toString();
                const MATCH_TIME = this.state.upcoming_matches[match].match_time.replace(":", "");
                const IS_MATCH_TIME = (parseInt(TIME) >= parseInt(MATCH_TIME)) ? true : false;
                if (is_today) { categoryDate = this.state.lang === "FR" ? "Aujourd'hui" : 'Today'; }
                else { categoryDate = new Date(date).toDateString().split(' ')[1] + ' ' + new Date(date).toDateString().split(' ')[2]; };
                if (index === 0) { dateElement = <p><span id="match-betting-category">{this.state.match_category} /</span> {this.state.upcoming_matches[match].match_confirmed ? categoryDate : localStorage.getItem("civfr_lang") === "FR" ? "Date à venir" : "Date TBD"}</p>; }
                else { dateElement = <p>{this.state.upcoming_matches[match].match_confirmed ? categoryDate : localStorage.getItem("civfr_lang") === "FR" ? "Date à venir" : "Date TBD"}</p>; }; 
                date = date.split('/');
                let temp_date = date[0];
                date[0] = date[1];
                date[1] = temp_date;
                date = date.join('/');
                

                const bet_index = this.state.bets.findIndex((x) => x.match_id === match);
                const bet_status = bet_index !== -1 ? {active: true, chosen_team: this.state.bets[bet_index].chosen_team} : {active: false};
                
                return (
                    <div key={index}>
                        {dateElement}
                        <div className="upcoming-match-container" id={`match_${this.state.upcoming_matches[match].match_id}`} key={index}>
                            {(is_today) && (this.state.upcoming_matches[match].channel !== 'null') && (IS_MATCH_TIME) ? <StreamEmbeds channel={this.state.upcoming_matches[match].channel} match_id={this.state.upcoming_matches[match].match_id}/> : null}
                            <span className="upcoming-match-division"><p name={`red-circle-${this.state.upcoming_matches[match].match_id}`} style={{display: "none", color: "red", fontSize: "8px", borderRadius: "50%", backgroundColor: "red"}}>ab</p> {this.state.upcoming_matches[match].category} - {this.state.upcoming_matches[match].team_one_name} vs {this.state.upcoming_matches[match].team_two_name}</span>
                            {this.state.authorized ? <img className="cog-image" src="./cog.png" onClick={this.show_settings} name={`match_${this.state.upcoming_matches[match].match_id}`}/> : null}
                            <span className='upcoming-match-date-span' id={`match-date-${this.state.upcoming_matches[match].match_id}`}>{this.state.upcoming_matches[match].match_confirmed ? categoryDate : localStorage.getItem("civfr_lang") === "FR" ? "Date à venir" : "Date TBD"} <span className="upcoming-match-time-span" id={`match-time-${this.state.upcoming_matches[match].match_id}`}>{this.state.upcoming_matches[match].match_confirmed ? `${this.state.upcoming_matches[match].match_time} (GMT)` : ""}</span></span>
                            {(is_today) && (IS_MATCH_TIME) ? <img id={`expand-stream-link-${this.state.upcoming_matches[match].match_id}`} className="match_stream_arrow" src="./down_arrow.png" onClick={this.alter_match_embed}></img> : null}
                            <MatchTeamOdds send_feedback={this.send_feedback_alert} active_bet={bet_status} go_to_basket={this.go_to_basket} match={this.state.upcoming_matches[match]}/>
                        </div>
                        <Settings send_feedback={this.send_feedback_alert} refresh_matches={this.load_data} match_details={this.state.upcoming_matches[match]}/>
                    </div>
                )
            })
        }
    }

    set_category (category) {
        this.props.set_category(category)
    }

    display_events () {
        this.props.display_events();
    }
    
    render () {
        return (
            <div>
                <MatchBanner category={this.state.match_category} change_page={this.set_category} display_events={this.display_events}/>
                <div className="category-sub-title">
                    {this.prepare_items()}
                </div>
            </div>
        );
    };
};

export default UpcomingMatches;