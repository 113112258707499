import react from 'react';
import axios from 'axios';

class BasketLink extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            basket: false
        }
        this.go_to_basket = this.go_to_basket.bind(this);
    };

    static getDerivedStateFromProps (props, state) {
        if (props.basket) {
            document.getElementById("basket-link").style.display = 'none';
            return {
                basket: props.basket
            }
        } else if ((props.basket !== state.basket) && !props.basket) {
            document.getElementById("basket-link").style.display = 'initial';
            return {
                basket: props.basket
            }
        } else return null;
    }

    componentDidMount () {
        this.load_basket()
    }

    componentDidUpdate () {
        this.load_basket()
    }

    load_basket () {
        axios.get('https://civfrcards.me/betting/my_basket')
        .then((res) => {
            if ((res.data.length > 0) && !this.state.basket) {
                document.getElementById("basket-link").style.display = 'initial';
            } else if (res.data.length === 0) {
                document.getElementById("basket-link").style.display = 'none';
            }
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        })
    }

    go_to_basket () {
        this.props.go_to_basket();
    }
    
    render () {
        return (
            <img id="basket-link" src="./basket_link.png" onClick={this.go_to_basket} style={{display: 'none'}}></img>
        );
    };
};

export default BasketLink;