import react from 'react';
import './events-banner.css';

class EventsBanner extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            
        }
        this.close_events = this.close_events.bind(this);
    };

    close_events () {
        this.props.close_events();
    }
    
    render () {
        return (
            <div id="events-banner">
                <img src="./menu_refold.png" onClick={this.close_events}></img>
                <span>{localStorage.getItem("civfr_lang") === "FR" ? "COMPÉTITIONS" : "TOP COMPETITIONS"}</span>
            </div>
        );
    };
};

export default EventsBanner