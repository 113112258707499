import react from 'react';
import './basket-items.css';
import axios from 'axios';

class DesktopBasketItems extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            basket_items: {
            },
            lang: localStorage.getItem("civfr_lang"),
            balance: 0
        }
        this.remove_from_basket = this.remove_from_basket.bind(this);
        this.submit_basket = this.submit_basket.bind(this);
        this.update_basket_item_value = this.update_basket_item_value.bind(this);
        this.update_item_id = this.update_item_id.bind(this);
    };

    componentDidMount () {
        this.update_basket();
    }

    componentDidUpdate (prevProps) {
        if ((prevProps.add_bets !== this.props.add_bets) && (prevProps.add_bets)) {
            this.submit_basket()
        }

        document.getElementById("desktop-basket").addEventListener(onchange, () => {
            this.update_basket();
        })

        axios.get('https://civfrcards.me/betting/user_balance')
        .then((res) => { 
            if (res.data.balance !== this.state.balance) {
                this.setState({balance: res.data.balance});
            } 
        })
        .catch((err) => { 
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (Object.keys(state.basket_items).length === 0) { return null };
        if (props.reset_basket === true) { 
            props.pass_basket_items({});
            return {basket_items: {}} 
        }
        else if (!props.current_basket === false) { props.pass_basket_items({}) } 
        else if (!props.current_item_id) { return {} }
        else if (state.basket_items && (props.new_basket_amount !== state.basket_items[props.current_item_id].amount)) {
            let current_basket = state.basket_items;
            current_basket[props.current_item_id].amount = props.new_basket_amount;
            props.pass_basket_items(current_basket);
            return {
              basket_items: current_basket
            };
        } else return {};
    }

    update_basket () {
        axios.get('https://civfrcards.me/betting/my_basket')
        .then((res) => {
            const BASKET = {};
            res.data.map((x) => BASKET[x.match_id] = x);
            this.setState({basket_items: BASKET});
            this.props.pass_basket_items(BASKET)
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        })
    }

    update_basket_item_value (e) {
        let maxValue = this.state.balance < 100000 ? this.state.balance : 100000;
        let value = e.target.value;
        if (!value || (parseInt(value) < 0)) { value = "0"; };
        if ((value.length > 1) && value[0] === "0") { value = value.substring(1); };
        if (value.length > 10) { return; };
        if (parseInt(value) > maxValue) { value = maxValue.toString() };
        this.props.pass_basket_item_id(e.target.id, value);
        this.props.pass_back_bet_amount(value);
    }

    update_item_id (e) {
        this.props.pass_basket_item_id(e.target.id, e.target.value);
    }

    remove_from_basket (e) {
        const MATCH_ID = e.target.title;
        axios.post('https://civfrcards.me/betting/remove_from_basket', {match_id: MATCH_ID})
        .then(() => this.update_basket())
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }

    submit_basket () {
        axios.post('https://civfrcards.me/betting/add_to_active_bets', {
            basket: this.state.basket_items
        })
        .then((res) => { 
            this.props.send_feedback({type: 'success', message: localStorage.getItem("civfr_lang") === 'FR' ? 'Succès' : 'Success'})
            this.update_basket();
            this.props.reload_balance();
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }
    
    render () {
        if (!this.state.basket_items) return;
        return (
            <div>
                {Object.keys(this.state.basket_items).map((objectKey, index) => {
                    const ODDS_VAR = this.state.basket_items[objectKey].team_one_name === this.state.basket_items[objectKey].chosen_team ? this.state.basket_items[objectKey].team_one_odds.toFixed(2) : this.state.basket_items[objectKey].team_two_odds.toFixed(2);
                    return (
                        <div className="basket-item" key={index}>
                            <div className="basket-item-match">
                                <span>{this.state.basket_items[objectKey].category} - {this.state.basket_items[objectKey].team_one_name} vs {this.state.basket_items[objectKey].team_two_name}</span>
                                <img className="basket-item-bin" src="./bin_small.png" alt="trash can" title={objectKey} onClick={this.remove_from_basket}></img>
                                <img className="basket-cross" src="./basket_cross.png" alt="trash can" title={objectKey} onClick={this.remove_from_basket}></img>
                            </div>
                            <div className="basket-item-choice">
                                <span className="basket-item-team">{this.state.basket_items[objectKey].chosen_team}</span>
                                <span className="basket-item-odds">{ODDS_VAR}</span>
                            </div>
                            <div className="basket-item-paragraph-container">
                                <p>{this.state.lang === 'FR' ? "Gagnant Du Winner" : "Match Winner"}</p>
                            </div>
                            <div className="basket-item-amount-container">
                                <div>
                                    <input type="number" min="0" className="basket-item-amount" id={this.state.basket_items[objectKey].match_id} value={this.state.basket_items[objectKey].amount} onChange={this.update_basket_item_value} onClick={this.update_item_id} onFocus={this.update_item_id}></input>
                                    <img src="./coin.png" alt="gold coin"></img>
                                </div>
                                <span className="basket-item-blue">Gains: {(this.state.basket_items[objectKey].amount * ODDS_VAR).toFixed(2)} <img src="./coin.png" alt="gold coin"></img></span>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    };
};

export default DesktopBasketItems;