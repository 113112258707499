import react from 'react';
import axios from 'axios';
import './keyboard.css';

class Keyboard extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            value: 0,
            match_id: null,
            balance: 0
        }
        this.alter_amount = this.alter_amount.bind(this);
    };

    componentDidMount () {
        axios.get('https://civfrcards.me/betting/user_balance')
        .then((res) => {
            this.setState({balance: res.data.balance});
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email";
            }
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.basket_item_amount !== state.value) {
            return { 
                value: props.basket_item_amount
            };
        } else { return null };
        
    }

    hide_keyboard () {
        const KEYBOARD = document.getElementById("keyboard");
        KEYBOARD.style.display = "none";
    }

    alter_amount (e) {
        let current_value = this.state.value.toString();
        let maxValue = this.state.balance < 100000 ? this.state.balance : "100000";
        if (current_value === "0") { current_value = ""};
        if ((current_value.length === 0) && ((e.target.title === ".") || (e.target.title === "0"))) return;
        let decimals = current_value.includes(".") ? current_value.split(".")[1].length : 0;
        if ((decimals >= 2) && (e.target.title.length === 1)) return;
        if ((e.target.title.length === 1) && (e.target.title !== ".") && (current_value.length === 0)) {
            current_value = e.target.title.toString();
            this.props.pass_back_bet_amount(current_value);
            return;
        } else if ((e.target.title !== ".") && (current_value[current_value.length-1] === '.') && (e.target.title.length === 1)) {
            current_value = current_value + e.target.title;
            this.props.pass_back_bet_amount(current_value);
            return;
        }
        if (e.target.title === "1000") current_value = parseInt(e.target.title);
        else if (e.target.title === "5000") current_value = parseInt(e.target.title);
        else if (e.target.title === maxValue) current_value = parseInt(e.target.title);
        else if (e.target.title === "del") { 
            if (current_value.toString().length > 1) current_value = current_value.toString().slice(0, current_value.toString().length-1);
            else current_value = "";
        }
        else if (e.target.title === ".") {
            if (current_value.toString().includes(".")) return;
            else current_value = current_value.toString() + ".";
        }
        else current_value = current_value.toString() + e.target.title;
        if (Number(current_value) > parseInt(maxValue)) current_value = maxValue.toString();
        this.props.pass_back_bet_amount(current_value);
    }
    
    render () {
        return (
            <div id="keyboard">
                <p className="keyboard-key-non-fixed" title="1" style={{gridRow: "1/2", gridColumn: "1/2"}} onClick={this.alter_amount}>1</p>
                <p className="keyboard-key-non-fixed" title="2" style={{gridRow: "1/2", gridColumn: "2/3"}} onClick={this.alter_amount}>2</p>
                <p className="keyboard-key-non-fixed" title="3" style={{gridRow: "1/2", gridColumn: "3/4"}} onClick={this.alter_amount}>3</p>
                <p className="keyboard-key fixed-button keyboard-navy" title="1000" style={{gridRow: "1/2", gridColumn: "4/5"}} onClick={this.alter_amount}><span onClick={this.alter_amount} title="1000">1000</span> <img className="keyboard-coin" title="1000" onClick={this.alter_amount} src='./coin.png' alt="gold coin"></img></p>
                <p className="keyboard-key-non-fixed" title="4" style={{gridRow: "2/3", gridColumn: "1/2"}} onClick={this.alter_amount}>4</p>
                <p className="keyboard-key-non-fixed" title="5" style={{gridRow: "2/3", gridColumn: "2/3"}} onClick={this.alter_amount}>5</p>
                <p className="keyboard-key-non-fixed" title="6" style={{gridRow: "2/3", gridColumn: "3/4"}} onClick={this.alter_amount}>6</p>
                <p className="keyboard-key fixed-button keyboard-navy" title="5000" style={{gridRow: "2/3", gridColumn: "4/5"}} onClick={this.alter_amount}><span onClick={this.alter_amount} title="5000">5000</span> <img className="keyboard-coin" title="5000" onClick={this.alter_amount} src='./coin.png' alt="gold coin"></img></p>
                <p className="keyboard-key-non-fixed" title="7" style={{gridRow: "3/4", gridColumn: "1/2"}} onClick={this.alter_amount}>7</p>
                <p className="keyboard-key-non-fixed" title="8" style={{gridRow: "3/4", gridColumn: "2/3"}} onClick={this.alter_amount}>8</p>
                <p className="keyboard-key-non-fixed" title="9" style={{gridRow: "3/4", gridColumn: "3/4"}} onClick={this.alter_amount}>9</p>
                <p className="keyboard-key fixed-button keyboard-navy" title={this.state.balance < 100000 ? this.state.balance.toString() : "100000"} style={{gridRow: "3/4", gridColumn: "4/5"}} onClick={this.alter_amount}><span onClick={this.alter_amount} title={this.state.balance < 100000 ? this.state.balance.toString() : "100000"}>MAX</span> <img className="keyboard-coin" title={this.state.balance < 100000 ? this.state.balance.toString() : "100000"} onClick={this.alter_amount} src='./coin.png' alt="gold coin"></img></p>
                <p className="keyboard-key-non-fixed" title="." style={{gridRow: "4/5", gridColumn: "1/2"}} onClick={this.alter_amount}>.</p>
                <p className="keyboard-key-non-fixed" title="0" style={{gridRow: "4/5", gridColumn: "2/3"}} onClick={this.alter_amount}>0</p>
                <p className="keyboard-key-non-fixed" style={{gridRow: "4/5", gridColumn: "3/4"}} onClick={this.alter_amount}><img className="keyboard-delete-button" title="del" src="./delete-keyboard-button.png" alt="gold delete button"></img></p>
                <p className="keyboard-key fixed-button keyboard-blue" style={{gridRow: "4/5", gridColumn: "4/5"}} onClick={this.hide_keyboard}><span>OK</span></p>
            </div>
        );
    };
};

export default Keyboard;