import react from 'react';
import './App.css';
import Header from './components/navbar/header';
import Basket from './components/basket/basket';
import Menu from './components/menu/menu';
import MyBets from './components/my-bets/my-bets';
import MatchesCategory from './components/matches/matches-category';
import Malm from './components/malm/malm';
import Leaderboard from './components/leaderboard/leaderboard';
import AlertBox from './components/alert/alert';
import BasketLink from './components/basket/basket-link';
import DesktopBasket from './components/basket/desktop-basket';
import Events from './components/events/events';
import LoggedOut from './components/logged-out/logged-out';

class App extends react.Component {
  constructor (props) {
    super(props);
    this.state = {
      category: 'All',
      my_bets: false,
      basket: false,
      header_rerender: false,
      admin: false,
      leaderboard: false,
      alert: false,
      feedback: {},
      desktopBasket: true,
      events: false,
      logged_out: true
    }
    this.display_side_menu = this.display_side_menu.bind(this);
    this.hide_side_menu = this.hide_side_menu.bind(this);
    this.display_match_category = this.display_match_category.bind(this);
    this.display_user_bets = this.display_user_bets.bind(this);
    this.reset_state = this.reset_state.bind(this);
    this.display_user_basket = this.display_user_basket.bind(this);
    this.rerender_header = this.rerender_header.bind(this);
    this.display_admin = this.display_admin.bind(this);
    this.display_leaderboard = this.display_leaderboard.bind(this);
    this.send_feedback_alert = this.send_feedback_alert.bind(this);
    this.display_events = this.display_events.bind(this);
    this.close_events = this.close_events.bind(this);
    this.logged_out = this.logged_out.bind(this);
  };

  componentDidMount () {
    document.title = 'CivFR Betting';
    if (!localStorage.getItem("civfr_lang")) {
      localStorage.setItem("civfr_lang", "FR")
    }
  }

  display_side_menu () {
    const SIDEBAR = document.getElementById("side-menu");
    const KEYBOARD = document.getElementById("keyboard");
    const ROOT_HEIGHT = document.getElementById("root").offsetHeight;
    const VH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const NEW_HEIGHT = ROOT_HEIGHT < VH ? `100vh` : `${ROOT_HEIGHT}px`;
    SIDEBAR.style.minHeight = NEW_HEIGHT;
    SIDEBAR.style.display = "block";
    SIDEBAR.classList.add("active-sidebar")
    try { KEYBOARD.style.display = "none" } catch (e) { return };
  };

  hide_side_menu () {
    const SIDEBAR = document.getElementById("side-menu");
    SIDEBAR.style.display = "none";
    document.getElementById("menu-icon").setAttribute("src", "./menu_unfold.png")
    SIDEBAR.classList.remove("active-sidebar");
  };

  display_match_category (category) {
    this.reset_state().then(() => this.setState({category: category}, () => this.hide_side_menu()));
  };

  display_user_bets () {
    this.reset_state().then(() => this.setState({my_bets: true}, () => this.hide_side_menu()))
  }

  display_user_basket () {
    if (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 750 ) {
      this.reset_state().then(() => this.setState({basket: true}, () => this.hide_side_menu()))
    } else {
      this.setState({desktopBasket: false}, () => {
        this.setState({desktopBasket: true}, () => {
          document.getElementById("desktop-basket").style.display = 'block';
        });
      });
    }
  }

  reset_state () {
    return new Promise((resolve, reject) => {
      this.setState({
        category: '',
        my_bets: false,
        basket: false,
        admin: false,
        leaderboard: false,
        alert: false,
        feedback: {},
        desktopBasket: true,
        events: false
      }, () => resolve());
    })
  }

  rerender_header () {
    this.setState({header_rerender: true}, () => this.setState({header_rerender: false}));
  }

  display_admin () {
    this.reset_state().then(() => { this.setState({admin: true}, () => this.hide_side_menu()); }).catch((err) => { return; }); // Alert?
  }

  display_leaderboard () {
    this.reset_state().then(() => { this.setState({leaderboard: true}, () => this.hide_side_menu()); }).catch((err) => { return; }); // Alert?
  }

  send_feedback_alert (feedback) {
    this.setState({alert: true, feedback: feedback}, () => { 
      setTimeout(() => { this.setState({alert: false, feedback: {}}); }, 3000);
    });
  }

  display_events () {
    this.setState({events: true});
  }

  close_events () {
    this.setState({events: false});
  }

  logged_out (logged_out) {
    if (logged_out) {
      this.reset_state().then(() => this.setState({logged_out: true, desktopBasket: false})).catch(() => { return; });
    } else {
      this.reset_state().then(() => this.setState({logged_out: false, desktopBasket: true, category: 'All'})).catch(() => { return; });
    }
  }
  
  render () {
    let component = this.state.logged_out ? (
      <div>
        <Header pass_logged_out={this.logged_out} pass_admin={this.display_admin} header_rerender={this.state.header_rerender} pass_side_menu={this.display_side_menu} hide_side_menu={this.hide_side_menu} send_feedback={this.send_feedback_alert} display_leaderboard={this.display_leaderboard} coins_refresh={this.rerender_header} display_match_page={this.display_match_category} display_user_bets={this.display_user_bets}/>
        <Menu display_leaderboard={this.display_leaderboard} coins_refresh={this.rerender_header} display_match_page={this.display_match_category} display_user_bets={this.display_user_bets} send_feedback={this.send_feedback_alert}/>  
        <LoggedOut />
      </div>
    ) : (<div>
    {this.state.alert && <AlertBox alert={this.state.alert} feedback={this.state.feedback} />}
    <Header pass_logged_out={this.logged_out} pass_admin={this.display_admin} header_rerender={this.state.header_rerender} pass_side_menu={this.display_side_menu} hide_side_menu={this.hide_side_menu} send_feedback={this.send_feedback_alert} display_leaderboard={this.display_leaderboard} coins_refresh={this.rerender_header} display_match_page={this.display_match_category} display_user_bets={this.display_user_bets}/>
    {this.state.events && <Events close_events={this.close_events} display_category={this.display_match_category}/>}   
    {this.state.desktopBasket && <DesktopBasket reload_balance={this.rerender_header} send_feedback={this.send_feedback_alert}/>}
    <Menu display_leaderboard={this.display_leaderboard} coins_refresh={this.rerender_header} display_match_page={this.display_match_category} display_user_bets={this.display_user_bets} send_feedback={this.send_feedback_alert}/>  
    {this.state.basket && <Basket reload_balance={this.rerender_header} send_feedback={this.send_feedback_alert}/>}
    {this.state.my_bets && <MyBets send_feedback={this.send_feedback_alert}/>}
    {(this.state.category.length > 0) && <MatchesCategory display_events={this.display_events} set_category={this.display_match_category} send_feedback={this.send_feedback_alert} match_category={this.state.category} go_to_basket={this.display_user_basket}/>}
    {this.state.admin && <Malm unauthorized_redirect={this.reset_state} send_feedback={this.send_feedback_alert}/>}
    {this.state.leaderboard && <Leaderboard send_feedback={this.send_feedback_alert}/>}
    <BasketLink send_feedback={this.send_feedback_alert} go_to_basket={this.display_user_basket} basket={this.state.basket}/>   
  </div>)
    return (
      component
    );
  };
};

export default App;