import react from 'react';
import './bet-items.css';
import axios from 'axios';

class BetItems extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            my_bets_this_week: [],
            my_bets_next_week: [],
            my_bets_finished: [],
            lang: localStorage.getItem("civfr_lang")
        }
        this.get_bet_items = this.get_bet_items.bind(this);
    };

    componentDidMount () {
        this.get_bet_items();
    };
 
    get_bet_items () {
        axios.get('https://civfrcards.me/betting/my_bets')
        .then((res) => {
            res.data.sort((a,b) => new Date(a.match_date).getTime() - new Date(b.match_date).getTime());
            let date = new Date().toLocaleDateString('en-US').split(',')[0];
            const TODAY = new Date(date);
            const TODAYS_DAY = TODAY.getDay()+1;
            const TODAYS_TIME = TODAY.getTime();
            const MS_IN_DAY = 86400000;
            const TIME_IN_WEEK = 7 * MS_IN_DAY;
            let my_bets_this_week = [];
            let my_bets_next_week = [];
            let my_bets_finished = [];
            res.data.map((x, index) => {
                const MATCH_DETAILS = new Date(x.match_date);
                const MATCH_DAY = MATCH_DETAILS.getDay()+1;
                const MATCH_TIME = MATCH_DETAILS.getTime();
                if (TODAYS_TIME > MATCH_TIME) { my_bets_finished.push(x); }
                else if ((TODAYS_DAY === MATCH_DAY) && (TODAYS_TIME === MATCH_TIME)) { my_bets_this_week.push(x); }
                else if ((TODAYS_DAY === MATCH_DAY) && (TODAYS_TIME < MATCH_TIME)) { my_bets_next_week.push(x); }
                else if (TODAYS_TIME < MATCH_TIME) {
                    const THIS_WEEK_TIME = ((7-MATCH_DAY) * MS_IN_DAY);
                    const DIFF_WEEK_TIME_DIFFERENCE = TIME_IN_WEEK - THIS_WEEK_TIME;
                    if ((MATCH_TIME - TODAYS_TIME) > DIFF_WEEK_TIME_DIFFERENCE) { my_bets_next_week.push(x); }
                    else { my_bets_this_week.push(x); };
                };
            });
            this.setState({my_bets_this_week: my_bets_this_week, my_bets_next_week: my_bets_next_week, my_bets_finished: my_bets_finished}, () => { this.props.send_bet_count(res.data.length); });
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    };
     
    render () {
        if ((this.state.my_bets_this_week.length === 0) && (this.state.my_bets_next_week.length === 0) && (this.state.my_bets_finished.length === 0)) return <div><p style={{color:"white",paddingLeft:"5px"}}>{this.state.lang === 'FR' ? "Vous n'avez aucun pari actif." : "You have no active bets." }</p></div>
        return (
            <div>
                <div>
                    {this.state.my_bets_finished.length ? <h3 className="betWeekTitle">{this.state.lang === 'FR' ? "En Attente Des Résultats" : "Awaiting Results" }</h3> : null}
                    <div>
                        {this.state.my_bets_finished.map((x, index) => {
                            const VARIABLE_ODDS = x.chosen_team === x.team_one_name ? x.team_one_odds : x.team_two_odds;
                            return (
                                <div className="bet-item" key={index}>
                                    <div className="bet-item-match">
                                        <span>{x.category} - {x.team_one_name} vs {x.team_two_name}</span>
                                    </div>
                                    <div className="bet-item-choice">
                                        <span className="bet-item-team">{x.chosen_team}</span>
                                        <span className="bet-item-odds">{VARIABLE_ODDS.toFixed(2)}</span>
                                    </div>
                                    <div className="bet-item-paragraph-container">
                                        <p>{this.state.lang === 'FR' ? "Gagnant Du Winner" : "Match Winner" }</p>
                                    </div>
                                    <div className="bet-item-amount-container">
                                        <div>
                                            <span className="bet-item-amount">{x.amount}</span>
                                            <img src="./coin.png" alt="gold coin"></img>
                                        </div>
                                        <span className="bet-item-blue  my-bet-odds">Gains: {(VARIABLE_ODDS * x.amount).toFixed(2)}<img src="./coin.png" alt="gold coin"></img></span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div>
                    {this.state.my_bets_this_week.length ? <h3 className="betWeekTitle">{this.state.lang === 'FR' ? "Cette Semaine" : "This Week" }</h3> : null}
                    <div>
                        {this.state.my_bets_this_week.map((x, index) => {
                            const VARIABLE_ODDS = x.chosen_team === x.team_one_name ? x.team_one_odds : x.team_two_odds;
                            return (
                                <div className="bet-item" key={index}>
                                    <div className="bet-item-match">
                                        <span>{x.category} - {x.team_one_name} vs {x.team_two_name}</span>
                                    </div>
                                    <div className="bet-item-choice">
                                        <span className="bet-item-team">{x.chosen_team}</span>
                                        <span className="bet-item-odds">{VARIABLE_ODDS.toFixed(2)}</span>
                                    </div>
                                    <div className="bet-item-paragraph-container">
                                        <p>{this.state.lang === 'FR' ? "Gagnant Du Winner" : "Match Winner" }</p>
                                    </div>
                                    <div className="bet-item-amount-container">
                                        <div>
                                            <span className="bet-item-amount">{x.amount}</span>
                                            <img src="./coin.png" alt="gold coin"></img>
                                        </div>
                                        <span className="bet-item-blue  my-bet-odds">Gains: {(VARIABLE_ODDS * x.amount).toFixed(2)}<img src="./coin.png" alt="gold coin"></img></span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div>
                    {this.state.my_bets_next_week.length ? <h3 className="betWeekTitle">{this.state.lang === 'FR' ? "La Semaine Prochaine" : "Next Week" }</h3> : null}
                    <div>
                        {this.state.my_bets_next_week.map((x, index) => {
                            const VARIABLE_ODDS = x.chosen_team === x.team_one_name ? x.team_one_odds : x.team_two_odds;
                            return (
                                <div className="bet-item" key={index}>
                                    <div className="bet-item-match">
                                        <span>{x.category} - {x.team_one_name} vs {x.team_two_name}</span>
                                    </div>
                                    <div className="bet-item-choice">
                                        <span className="bet-item-team">{x.chosen_team}</span>
                                        <span className="bet-item-odds">{VARIABLE_ODDS.toFixed(2)}</span>
                                    </div>
                                    <div className="bet-item-paragraph-container">
                                        <p>{this.state.lang === 'FR' ? "Gagnant Du Winner" : "Match Winner" } </p>
                                    </div>
                                    <div className="bet-item-amount-container">
                                        <div>
                                            <span className="bet-item-amount">{x.amount}</span>
                                            <img src="./coin.png" alt="gold coin"></img>
                                        </div>
                                        <span className="bet-item-blue  my-bet-odds">Gains: {(VARIABLE_ODDS * x.amount).toFixed(2)}<img src="./coin.png" alt="gold coin"></img></span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    };
};

export default BetItems;