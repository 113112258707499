import react from 'react';
import './basket-banner.css';
import axios from 'axios';

class BasketBanner extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            basketCount: 0,
            lang: localStorage.getItem("civfr_lang")
        }
        this.clearBasket = this.clearBasket.bind(this);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.basketCount !== state.basketCount) {
            return {
              basketCount: props.basketCount
            };
        } else return null;
    }

    clearBasket () {
        axios.post('https://civfrcards.me/betting/empty_basket')
        .then((res) => this.props.empty_basket())
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        });
    }

    close_basket_menu () {
        document.getElementById("desktop-basket").style.display = 'none';
    }
    
    render () {
        return (
            <div id="basket-banner">
                <span><img id="basket-banner-cart" src="./shopping_cart.png" alt="shopping cart"></img>{this.state.lang === 'FR' ? "MA SÉLECTION" : "MY SELECTION"} ({this.state.basketCount})</span>
                <img id="basket-banner-bin" src="./bin_large.png" alt="trash can" onClick={this.clearBasket}></img>
                <img id="desktop-basket-button" src="./menu_refold.png" onClick={this.close_basket_menu}></img>
            </div>
        );
    };
};

export default BasketBanner