import react from 'react';

class LoggedOut extends react.Component {
    constructor (props) {
        super(props);
    };
    
    render () {
        return (
            <div id="logged-out-component" style={{width: "100%"}}>
                {localStorage.getItem("civfr_lang") === "FR" ? <p style={{width: "100%", color: "white", fontSize: "16px", textAlign: "center"}}><a style={{color: "white"}} href='https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email'>Connectez-vous</a> pour commencer!</p> : <p style={{width: "100%", color: "white", fontSize: "16px", textAlign: "center"}}><a tyle={{color: "white"}} href='https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email'>Login</a> to get started!</p>}
            </div>
        );
    };
};

export default LoggedOut;