import react from 'react';
import UpcomingMatches from './upcoming-matches';

class MatchesCategory extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            match_category: ''
        }
        this.go_to_basket = this.go_to_basket.bind(this);
        this.send_feedback_alert = this.send_feedback_alert.bind(this);
        this.set_category = this.set_category.bind(this);
        this.display_events = this.display_events.bind(this);
    };

    static getDerivedStateFromProps(props, state) {
        if (props.match_category !== state.match_category) {
            return {
              match_category: props.match_category
            };
        } else { return null; };
    }

    go_to_basket () {
        this.props.go_to_basket();
    }

    send_feedback_alert (feedback) {
        this.props.send_feedback(feedback)
    }

    set_category (category) {
        this.props.set_category(category);
    }

    display_events () {
        this.props.display_events()
    }

    bannerRedirect () {
        window.open("https://gvo.deals/Malm", "_blank");
    }
    
    render () {
        return (
            <div id="categoryMatches">
                <div id="mobileBanner" onClick={this.bannerRedirect}></div>
                <UpcomingMatches display_events={this.display_events} set_category={this.set_category} send_feedback={this.send_feedback_alert} match_category={this.state.match_category} go_to_basket={this.go_to_basket}/>
            </div>
        );
    };
};

export default MatchesCategory;