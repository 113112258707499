import react from 'react';
import './embeds.css';
import axios from 'axios';

class StreamEmbeds extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            channel: this.props.channel,
            id: this.props.match_id,
            live: false
        }
    };

    componentDidMount () {
        if (this.state.channel) {
            axios.post(`https://civfrcards.me/betting/is_channel_live`, {
                channel: this.state.channel
            })
            .then((res) => {
                if (res.data.live) {
                    this.setState({live: true})
                    let circles = document.getElementsByName(`red-circle-${this.state.id}`);
                    for (let i = 0; i < circles.length; i++) { circles[i].style.display = 'inline'; };
                    document.getElementById(`match-date-${this.state.id}`).innerText = 'Live Now';
                    document.getElementById(`match_${this.state.id}`).classList.add("desktop-live-channel");
                }
            })
            .catch((err) => {
                console.error(err);
            })
        }
    }
    
    render () {
        console.log(this.state.channel)
        return (
            this.state.live ? (
                <div className={`${this.state.channel} live-stream-container`}>
                    <div className="live-streams">
                        <div className="stream-embed-container">
                            <iframe title="stream_one" src={`https://player.twitch.tv/?channel=${this.state.channel.split('/')[3]}&parent=civfrcards.me`} frameborder="0" allowFullScreen={true} scrolling="no" width="100%"></iframe>
                        </div>
                    </div>
                </div>
            ) : null
        );
    };
};

export default StreamEmbeds;