import react from 'react';
import './match-banner.css';

class MatchBanner extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            category: this.props.category
        }
        this.change_page = this.change_page.bind(this);
        this.display_events = this.display_events.bind(this);
    };

    componentDidMount () {
        const ELEMENT_ID = this.state.category.split(" ")[0] + "_image";
        for (let element = 0; element < document.getElementsByClassName("secondary-navbar-image").length; element++) {
            if (document.getElementsByClassName("secondary-navbar-image")[element].id === ELEMENT_ID) {
                document.getElementsByClassName("secondary-navbar-image")[element].classList.add("secondary-navbar-selected");
            } else if (document.getElementsByClassName("secondary-navbar-image")[element].classList.contains("secondary-navbar-selected")) {
                document.getElementsByClassName("secondary-navbar-image")[element].classList.remove("secondary-navbar-selected");
            }
        }
    }
    
    change_page (event) {
        let category = event.target.id;
        switch (category.slice(0,3)) {
            case 'All':
                category = 'All';
                break;
            case 'Civ':
                category = 'Civ FR';
                break;
            case 'CPL':
                category = 'CPL';
                break;
            case 'CWC':
                category = 'CWC';
                break;
            case 'Oth':
                category = 'Others';
                break;
            default:
                category = 'CWC';
        }
        this.props.change_page(category);
    } 

    display_basket_menu () {
        document.getElementById("desktop-basket").style.display = 'block';
    }

    display_events () {
        this.props.display_events();
    }

    bannerRedirect () {
        window.open("https://gvo.deals/Malm", "_blank");
    }

    render () {
        return (
            <div id="desktop-match-banner">
                <div id="desktop-banner-image-container" onClick={this.bannerRedirect}>
                </div>
                <div id="secondary-navbar">
                    <div className="secondary-navbar-menu-icon-container left" onClick={this.display_events}>
                        <img src="./cup.png" onClick={this.display_events}></img>
                        <span className="secondary-navbar-menu-span" onClick={this.display_events}>Events &nbsp; &gt;</span>
                    </div>
                    <div id="secondary-navbar-image-container">
                        <div id="All_image" className="secondary-navbar-image" value="All" onClick={this.change_page}>
                            <img id="All-nav-image" src="./bets_category_all.png" onClick={this.change_page}></img>
                        </div>
                        <div id="CWC_image" className="secondary-navbar-image" value="CWC" onClick={this.change_page}>
                            <img id="CWC-nav-image" src="./bets_category_CWC.png" value="CWC" onClick={this.change_page}></img>
                        </div>
                        <div id="Civ_image" className="secondary-navbar-image" name="Civ FR" onClick={this.change_page}>
                            <img id="Civ-nav-image" src="./bets_category_CivFR.png" onClick={this.change_page}></img>
                        </div>
                        <div id="CPL_image" className="secondary-navbar-image" name="CPL" onClick={this.change_page}>
                            <img id="CPL-nav-image" src="./bets_category_CPL.png" onClick={this.change_page}></img>
                        </div>
                        <div id="Others_image" className="secondary-navbar-image" name="Others" onClick={this.change_page}>
                            <img id="Others-nav-image" src="./bets_category_others.png" onClick={this.change_page}></img>
                        </div>
                    </div>
                    <div className="secondary-navbar-menu-icon-container right" onClick={this.display_basket_menu}>
                        <img src="./cart.png"></img>
                    </div>
                </div>
            </div>
        );
    };
};

export default MatchBanner;