import react from 'react';
import './bets-banner.css';

class BetsBanner extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            bet_count: 0,
            lang: localStorage.getItem("civfr_lang")
        }
    };

    static getDerivedStateFromProps (props, state) {
        if (props.bet_count !== state.bet_count) { return {bet_count: props.bet_count} }
        else return null;
    }
    
    render () {
        return (
            <div id="bets-banner">
                <span><img id="bets-banner-cart" src="./shopping_cart.png" alt="shopping cart"></img>{this.state.lang === 'FR' ? "MES PARIS" : "MY BETS" } ({this.state.bet_count})</span>
            </div>
        );
    };
};

export default BetsBanner