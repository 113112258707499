import react from 'react';
import axios from 'axios';
import './settings.css';

class Settings extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            match: {},
            authorized: false
        };
        this.setCategory = this.setCategory.bind(this);
        this.setDate = this.setDate.bind(this);
        this.setLink = this.setLink.bind(this);
        this.setTime = this.setTime.bind(this);
        this.update_team_one_name = this.update_team_one_name.bind(this);
        this.update_team_two_name = this.update_team_two_name.bind(this);
        this.handle_match_update = this.handle_match_update.bind(this);
        this.update_match_confirmed = this.update_match_confirmed.bind(this);
    };

    static getDerivedStateFromProps(props, state) {
        const UPDATED_STATE = {};
        if (props.match_details !== state.match) { UPDATED_STATE.match = props.match_details; };
        return UPDATED_STATE;
    }

    componentDidMount () {
        axios.get('https://civfrcards.me/betting/validate_user')
        .then(() => { this.setState({authorized: true})})
        .catch((err) => { 
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            }
        });
    }

    setCategory (event) {
        const MATCH = this.state.match;
        MATCH.category = event.target.value;
        this.setState({match: MATCH});
    }

    setDate (event) {
        const MATCH = this.state.match;
        MATCH.match_date = event.target.value;
        this.setState({match: MATCH});
    }

    setTime (event) {
        const MATCH = this.state.match;
        MATCH.match_time = event.target.value;
        this.setState({match: MATCH});
    }

    setLink (event) {
        const MATCH = this.state.match;
        MATCH.channel = event.target.value;
        this.setState({match: MATCH})
    }

    update_team_one_name (event) {
        const MATCH = this.state.match;
        MATCH.team_one_name = event.target.value;
        this.setState({match: MATCH});
    }

    update_team_two_name (event) {
        const MATCH = this.state.match;
        MATCH.team_two_name = event.target.value;
        this.setState({match: MATCH});
    }

    update_match_confirmed () {
        const MATCH = this.state.match;
        MATCH.match_confirmed = MATCH.match_confirmed ? 0 : 1;
        this.setState({match: MATCH});
    }

    handle_match_update (event) {
        event.preventDefault();
        const MATCH_ID = event.target.match_id.value;
        const CATEGORY = event.target.category.value;
        const DATE = event.target.match_date.value;
        const TIME = event.target.match_time.value;
        const LINK = event.target.link.value;
        const DATE_ELEMENT = document.getElementById("match_form_date_feedback_"+ MATCH_ID);
        const TIME_ELEMENT = document.getElementById("match_form_time_feedback_" + MATCH_ID);
        const LINK_ELEMENT = document.getElementById("match_form_link_feedback_" + MATCH_ID);
        const WINNER_ELEMENT = document.getElementById("match_form_winner_feedback_" + MATCH_ID);
        DATE_ELEMENT.style.display = 'none';
        TIME_ELEMENT.style.display = 'none';
        LINK_ELEMENT.style.display = 'none';
        if (DATE.match(/^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/) === null) {
            DATE_ELEMENT.style.display = 'initial';
            return;
        }
        if (TIME.match(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/) === null) {
            TIME_ELEMENT.style.display = 'initial';
            return;
        }
        if ((LINK.length > 0) && (LINK.match(/https:\/\/twitch.tv\/.[a-zA-Z]/) === null)) {
            LINK_ELEMENT.style.display = 'initial';
            return;
        }
        function verify (message) {
            return new Promise((resolve, reject) => {
                // eslint-disable-next-line no-restricted-globals
                let confirmed = confirm((message))
                if (confirmed) {
                    resolve(true);
                } else {
                    reject();
                }
            })
        }
        if (event.nativeEvent.submitter.name === 'modify') {
            axios.post('https://civfrcards.me/betting/update_match_details', {
                match_id: MATCH_ID,
                match_date: DATE,
                match_time: TIME,
                channel: LINK,
                category: CATEGORY,
                team_one_name: this.state.match.team_one_name,
                team_two_name: this.state.match.team_two_name,
                match_confirmed: this.state.match.match_confirmed
            })
            .then((res) => {
                this.props.send_feedback({type: 'success', message: localStorage.getItem("civfr_lang") === 'FR' ? "Succès" : "Success!"})
                this.props.refresh_matches();
                document.getElementById(`match_${MATCH_ID}`).style.display = 'block';
                document.getElementById(`settings_${MATCH_ID}`).style.display = 'none';
            })
            .catch((err) => { 
                if (err.response.status === 500) {
                    this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                } else {
                    window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
                }
            });
        } else if (event.nativeEvent.submitter.name === 'cancel') {
            const MESSAGE = localStorage.getItem("civfr_lang") === 'FR' ? `Êtes-vous sûr de vouloir supprimer le match ${this.state.match.team_one_name} vs ${this.state.match.team_two_name}` : `Are you sure you want to delete match ${this.state.match.team_one_name} vs ${this.state.match.team_two_name}?`;
            verify(MESSAGE)
            .then(() => {
                axios.post('https://civfrcards.me/betting/delete_bet', {
                    match_id: MATCH_ID
                })
                .then((res) => {
                    this.props.send_feedback({type: 'success', message: localStorage.getItem("civfr_lang") === 'FR' ? "Succès" : "Success!"})
                    this.props.refresh_matches();
                    document.getElementById(`match_${MATCH_ID}`).style.display = 'block';
                    document.getElementById(`settings_${MATCH_ID}`).style.display = 'none';
                })
                .catch((err) => { 
                    if (err.response.status === 500) {
                        this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                    } else {
                        window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
                    }
                });
            }).catch(() => { return; });
        } else if (event.nativeEvent.submitter.name === 'complete') {
            if (!event.target.winner.value) { 
                WINNER_ELEMENT.style.display = 'initial';
            } 
            else {
                const MESSAGE = localStorage.getItem("civfr_lang") === 'FR' ? `Êtes-vous sûr de vouloir marquer ${event.target.winner.value} comme vainqueur du ${this.state.match.team_one_name} vs ${this.state.match.team_two_name}` : `Are you sure you want to mark ${event.target.winner.value} as the winner of ${this.state.match.team_one_name} vs ${this.state.match.team_two_name}?`;
                verify(MESSAGE)
                .then(() => {
                    axios.post('https://civfrcards.me/betting/complete_match', {
                        match_id: MATCH_ID,
                        match_winner: event.target.winner.value
                    })
                    .then((res) => {
                        this.props.send_feedback({type: 'success', message: localStorage.getItem("civfr_lang") === 'FR' ? "Succès" : "Success!"})
                        this.props.refresh_matches();
                        document.getElementById(`match_${MATCH_ID}`).style.display = 'block';
                        document.getElementById(`settings_${MATCH_ID}`).style.display = 'none';
                    })
                    .catch((err) => { 
                        if (err.response.status === 500) {
                            this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
                        } else {
                            window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
                        }
                    });
                }).catch(() => { return; });
            }
        } else { return };
    }

    close_settings (event) {
        const MATCH_ID = event.target.name;
        document.getElementById(`match_${MATCH_ID}`).style.display = 'block';
        document.getElementById(`settings_${MATCH_ID}`).style.display = 'none';
    }

    render () {
        const form = this.state.authorized === true ? 
            <div className="match_setting_container" id={`settings_${this.state.match.match_id}`} style={{display: 'none'}}>
                <form className="settings_form" onSubmit={this.handle_match_update}>
                    <input type="hidden" name="match_id" value={this.state.match.match_id}></input>
                    <select className="match_settings_category_select" value={this.state.match.category} name="category" onChange={this.setCategory}>
                        <option value="CWC">CWC</option>
                        <option value="Civ FR">Civ FR</option>
                        <option value="CPL">CPL</option>
                        <option value="Others">Others</option>
                    </select>
                    <img className="settings-cog-image" src="./cog.png" name={this.state.match.match_id} onClick={this.close_settings}/>
                    <span className="match_settings_span">{localStorage.getItem("civfr_lang") === 'FR' ? "Gagnant du Match" : "Match Winner"}</span>
                    <input className="match_date_setting_input" name="match_date" type="text" placeholder="dd/mm/yyyy" value={this.state.match.match_date} onChange={this.setDate}></input>
                    <input className="match_time_setting_input" name="match_time" type="text" placeholder="hh/mm" value={this.state.match.match_time} onChange={this.setTime}></input>
                    <label for="match_confirmed" className="match_confirmed_label">{localStorage.getItem("civfr_lang") === 'FR' ? "Date et heure confirmées?" : "Date & Time confirmed?"}</label>
                    <input type="checkbox" checked={this.state.match.match_confirmed ? true : false} className="match_confirmed" name="match_confirmed" onClick={this.update_match_confirmed}></input>
                    <input className="settings_team_one_name" value={this.state.match.team_one_name} onChange={this.update_team_one_name}></input>
                    <span className="team_one_name_odds">{this.state.match.team_one_odds.toFixed(2)}</span>
                    <span className="team_two_name_odds">{this.state.match.team_two_odds.toFixed(2)}</span>
                    <input type="text" className="settings_team_two_name" value={this.state.match.team_two_name} onChange={this.update_team_two_name}></input>
                    <input type="radio" className="team_one_name" id="team_one_name" name="winner" value={this.state.match.team_one_name}></input>
                    <label className="winner_radio_label_team_one" htmlFor="team_one_name">{localStorage.getItem("civfr_lang") === 'FR' ? 'Gagnant' : 'Winner'}</label>
                    <input type="radio" className="team_two_name" id="team_two_name" name="winner" value={this.state.match.team_two_name}></input>
                    <label className="winner_radio_label_team_two" htmlFor="team_two_name">{localStorage.getItem("civfr_lang") === 'FR' ? 'Gagnant' : 'Winner'}</label>
                    <input className="stream_link_input" name="link" type="text" placeholder={localStorage.getItem("civfr_lang") === 'FR' ? "Lien de stream, le cas echeant" : "Stream link, if applicable"} value={this.state.match.channel !== 'null' ? this.state.match.channel : ""} onChange={this.setLink}></input>
                    <button className="modify_bet_setting_button" type="submit" value="APPLY MODIFICATIONS" name="modify">{localStorage.getItem("civfr_lang") === 'FR' ? "APPLIQUER LES MODIFICATIONS" : "APPLY MODIFICATIONS"}</button>
                    <button className="cancel_bet_setting_button" type="submit" value="CANCEL BET" name="cancel"><img src="./bin.png" className="cancel_bet_bin_image"/>{localStorage.getItem("civfr_lang") === 'FR' ? "ANNULER LE PARI" : "CANCEL BET"}</button>
                    <button className="validate_match_setting_button" type="submit" value="VALIDATE BET" name="complete"><img src="./tick.png" className="complete_bet_tick_image"/>{localStorage.getItem("civfr_lang") === 'FR' ? "VALIDER LE PARI" : "VALIDATE BET"}</button>
                </form>
                <span className="form_feedback_error" id={`match_form_date_feedback_${this.state.match.match_id}`} style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Format incorrect, veuillez indiquer une date sous la forme de mm/dd/yyyy" : "Incorrect format, please provide a date in the form of mm/dd/yyyy"}</span>
                <span className="form_feedback_error" id={`match_form_time_feedback_${this.state.match.match_id}`} style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Format incorrect, veuillez indiquer une heure au format hh:mm" : "Incorrect format, please provide a time in the format of hh:mm"}</span>
                <span className="form_feedback_error" id={`match_form_link_feedback_${this.state.match.match_id}`} style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Veuillez fournir un lien de flux valide" : "Please provide a valid stream link: https://twitch.tv/channel"}</span>
                <span className="form_feedback_error" id={`match_form_winner_feedback_${this.state.match.match_id}`} style={{display: 'none'}}>{localStorage.getItem("civfr_lang") === 'FR' ? "Veuillez indiquer un gagnant!" : "Please provide a winner!"}</span>
            </div>
         : null;
        return (
            form
        );
    };
};

export default Settings;