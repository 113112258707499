import react from 'react';
import './my-bets.css';
import BetsBanner from './bets-banner';
import BetItems from './bet-items';
import BetHistory from './bet-history';

class MyBets extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            bet_count: 0
        }
        this.set_bet_count = this.set_bet_count.bind(this);
        this.send_feedback_alert = this.send_feedback_alert.bind(this);
    };

    set_bet_count (count) { 
        this.setState({bet_count: count}) 
    };

    send_feedback_alert (feedback) {
        this.props.send_feedback(feedback);
    }
    
    render () {
        return (
            <div id="bets">
                <BetsBanner bet_count={this.state.bet_count}/>
                <BetItems send_bet_count={this.set_bet_count} send_feedback={this.send_feedback_alert}/>
                <BetHistory />
            </div>
        );
    };
};

export default MyBets;