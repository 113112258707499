import react from 'react';
import './login.css';
import axios from 'axios';

class Login extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("civfr_lang"),
            is_logged_in: false
        }
    };

    componentDidMount () {
        axios.get("https://civfrcards.me/betting/is_logged_in")
        .then((res) => {
            this.setState({is_logged_in: true})
            this.props.is_logged_out(false);
        })
        .catch((err) => {
            this.props.is_logged_out(true);
        });
    }

    logout () {
        window.location.href = 'https://civfrcards.me/betting/logout'
    }

    login () {
        window.location.href = 'https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email'
    }
    
    render () {
        let account_button = this.state.is_logged_in ? <button id="login-button" onClick={this.logout}><img src='./avatar.png' alt="avatar of a man" id="login-logo"></img> { localStorage.getItem("civfr_lang") === "FR" ? 'DÉCONNECTER' : 'LOGOUT'}</button> : <button id="login-button" onClick={this.login}><img src='./avatar.png' alt="avatar of a man" id="login-logo"></img>{this.state.lang === 'FR' ? "CONNECTEZ" : "LOGIN" }</button>
        return (
            account_button
        );
    };
};

export default Login;