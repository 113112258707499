import react from 'react';
import axios from 'axios';
import './basket-review.css';

class BasketReview extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            basketTotalBet: 0,
            basketTotalGains: 0,
            basketCount: 0,
            lang: localStorage.getItem("civfr_lang")
        }
        this.add_bets = this.add_bets.bind(this);
    };

    static getDerivedStateFromProps(props, state) {
        const UPDATED_STATE = {};
        if (props.basketTotalBet !== state.basketTotalBet) {
            UPDATED_STATE.basketTotalBet = props.basketTotalBet
        }
        if (props.basketTotalGains !== state.basketTotalGains) {
            UPDATED_STATE.basketTotalGains = props.basketTotalGains;
        }
        if (props.basketCount !== state.basketCount) {
            UPDATED_STATE.basketCount = props.basketCount;
        }
        return UPDATED_STATE;
    }

    add_bets () {
        const TOTAL_BETS = this.state.basketTotalBet;
        const BASKET_COUNT = this.state.basketCount;
        if (BASKET_COUNT === 0) { return; }
        axios.get('https://civfrcards.me/betting/user_balance')
        .then((res) => {
            if (TOTAL_BETS > res.data.balance) { 
                this.props.send_feedback({type: 'error', message: this.state.lang === 'FR' ? "Le montant total de votre panier est supérieur à votre solde." : 'Your total basket amount is greater than your balance.'}); 
            }
            else { this.props.add_bets(); };
        })
        .catch((err) => {
            if (err.response.status === 500) {
                this.props.send_feedback({type: 'error', message: localStorage.getItem("civfr_lang") === 'FR' ? err.response.data.french : err.response.data.english})
            } else {
                window.location.href = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=c1rkmt04tdpchu4j4f3btixlsm7kay&redirect_uri=https://civfrcards.me/betting/authenticate&scope=user:read:email"
            }
        })
    } 
    
    render () {
        return (
            <div id="basket-review">
                <div id="total-bets-container">
                    <span id="total-bets-span">{this.state.lang === 'FR' ? "Paris Totaux" : "Total Bets"} ({this.state.basketCount})</span>
                    <span id="total-bets-cost">{this.state.basketTotalBet} <img src="./coin.png" alt="gold coin"></img></span>
                </div>
                <div id="total-returns-container">
                    <span id="total-returns-span">{this.state.lang === 'FR' ? "Rendements Maximaux" : "Maximum Returns" }</span>
                    <span id="bet-returns">{this.state.basketTotalGains.toFixed(2)} <img src="./coin.png" alt="gold coin"></img></span>
                </div>
                <div id="basket-validation-container">
                    <button id="basket-confirm-button" onClick={this.add_bets}>{this.state.lang === 'FR' ? "Valider Mes Paris" : "VALIDATE MY BETS"}</button>
                </div>
            </div>
        );
    };
};

export default BasketReview;