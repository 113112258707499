import react from 'react';
import axios from 'axios';
import EventsBanner from './events-banner';
import './events-side-menu.css';

class Events extends react.Component {
    constructor (props) {
        super(props);
        this.state = {
            cwc: 0,
            civ: 0,
            cpl: 0,
            others: 0
        }
        this.close_events = this.close_events.bind(this);
        this.go_to_civ = this.go_to_civ.bind(this);
        this.go_to_cpl = this.go_to_cpl.bind(this);
        this.go_to_cwc = this.go_to_cwc.bind(this);
        this.go_to_others = this.go_to_others.bind(this);
    };

    componentDidMount () {
        axios.get('https://civfrcards.me/betting/match_category?category=CWC')
        .then((res) => { this.setState({cwc: res.data.length}); }).catch(() => { return; });
        axios.get('https://civfrcards.me/betting/match_category?category=Civ FR')
        .then((res) => { this.setState({civ: res.data.length}); }).catch(() => { return; });
        axios.get('https://civfrcards.me/betting/match_category?category=CPL')
        .then((res) => { this.setState({cpl: res.data.length}); }).catch(() => { return; });
        axios.get('https://civfrcards.me/betting/match_category?category=Others')
        .then((res) => { this.setState({others: res.data.length}); }).catch(() => { return; });
    }

    close_events () {
        this.props.close_events();
    }

    go_to_cwc () {
        this.props.display_category('CWC')
        this.props.close_events();
    }

    go_to_civ () {
        this.props.display_category('Civ FR')
        this.props.close_events();
    }

    go_to_cpl () {
        this.props.display_category('CPL')
        this.props.close_events();
    }

    go_to_others () {
        this.props.display_category('Others')
        this.props.close_events();
    }
    
    render () {
        return (
            <div id="events-side-menu">
                <EventsBanner close_events={this.close_events}/>
                <div>
                    <div id="cwc-events" onClick={this.go_to_cwc}>
                        <div>
                            <img src="./bets_category_CWC.png" onClick={this.go_to_cwc}></img>
                            <span onClick={this.go_to_cwc}>Civ World Champ.</span>
                        </div>
                        <span>{this.state.cwc}</span>
                    </div>
                    <div id="civ-events" onClick={this.go_to_civ}>
                        <div>
                            <img src="./bets_category_CivFR.png" onClick={this.go_to_civ}></img>
                            <span onClick={this.go_to_civ}>Civ FR</span>
                        </div>
                        <span>{this.state.civ}</span>
                    </div>
                    <div id="cpl-events" onClick={this.go_to_cpl}>
                        <div>
                            <img src="./bets_category_CPL.png" onClick={this.go_to_cpl}></img>
                            <span onClick={this.go_to_cpl}>Civ Players League</span>
                        </div>
                        <span>{this.state.cpl}</span>
                    </div>
                    <div id="others-events" onClick={this.go_to_others}>
                        <div>
                            <img src="./bets_category_others.png" onClick={this.go_to_others}></img>
                            <span onClick={this.go_to_others}>{localStorage.getItem("civfr_lang") === 'FR' ? "Autres" : "Others"}</span>
                        </div>
                        <span>{this.state.others}</span>
                    </div>
                </div>
            </div>
        );
    };
};

export default Events;